<template>
  <div v-bind:class="[isFlipped ? 'flip-container flipped': 'flip-container']">
    <div class="flipper">
      <div class="front">
        <v-card width="160px" height="232px" class="justify-center d-flex align-center ml-3 mr-3 mb-6">
          <v-btn
            icon
            fab
          >
            <v-icon
              size="50"
            >
              {{ icons.mdiCards }}
            </v-icon>
          </v-btn>
        </v-card>
      </div>
      <div class="back">
        <v-card width="160px" height="232px" class="justify-center d-flex align-center ml-3 mr-3 mb-6">
          <v-img :src="require('@/assets/images/games/blackjack/cards/'+ cardNumber +'.png')"></v-img>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, emit, computed, watch } from '@vue/composition-api'
import { mdiCards } from '@mdi/js'
import store from '@/store'

export default {
  name: 'Card',
  props: {
    number: String,
    flipped: Boolean
  },
  setup(props, {emit}) {

    const cardNumber = computed (() => props.number)
    const isFlipped = ref(props.flipped)

    watch(() => props.flipped, (val, prevVal) => {
      isFlipped.value = val
    },{deep:true})

    return {
      cardNumber,
      isFlipped,
      icons: {
        mdiCards
      }
    }
  }

};
</script>

<style type='text/css' scoped>
i.frontFlipBtn,
i.backFlipBtn {
    position:absolute;
    right: 20px;
    top: 20px;
    color:#FFFFFF;
}
i.backFlipBtn {
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
}
.flip-container {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
}
.flip-container {
  min-height: 120px;
}
.flipper {
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  position: relative;
}
.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  top: 0;
  left: 0;
  width: 100%;
}
.back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  position: absolute;
}
.flip-container.flipped .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.flip-container.flipped .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.front {
  z-index: 2;
}
</style>
