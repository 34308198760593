<template>
  <div id="deck">
    <div class="cards-left">
      Cards left: <strong>{{ cardsLeft }}</strong>
    </div>
    <template v-for="(card,index) in fakeCards">
      <div class="fake-card" :style="`right:${index*6}px`">
        <Card
          :number="card.image"
          :index="index"
          :flipped="false"
        ></Card>
      </div>
    </template>
  </div>
</template>

<script>
import Card from './Card.vue'
import { ref, watch } from '@vue/composition-api'

export default {
  components: {
    Card
  },
  props: {
    cards: Array
  },
  setup(props) {

    const cardsLeft = ref(props.cards.length)

    watch(() => props.cards.length, (val, prevVal) => {
       cardsLeft.value = val
    })

    const fakeCards = props.cards.slice(0,5)

    return {
      fakeCards,
      cardsLeft
    }
  }
}
</script>

<style lang="scss" scoped>
  #deck {
    position: absolute;
    top: 0;
    right: 0;
    .fake-card {
      position: absolute;
      top: 40px;
    }
    .cards-left {
      padding-right: 30px;
    }
  }
</style>
