<template>
  <div id="dealer-hand">
    <v-chip color="secondary" v-if="totalLocal > 0 && cardsInHand[0].flipped == true">
      <strong>{{totalLocal}}</strong>
    </v-chip>
    <transition-group name="card" :duration="500">
      <div class="card" v-for="card in cardsInHand" :key="card.index">
        <Card
          :number="card.image"
          :flipped="card.flipped"
        ></Card>
      </div>
    </transition-group>
  </div>
</template>

<script>
import Card from './Card.vue'
import { ref, watch } from '@vue/composition-api'

export default {
  components: {
    Card
  },
  props: {
    cards: Array,
    total: Number,
  },
  setup(props, {emit}) {

    const cardsInHand = ref(props.cards)

    const totalLocal = ref(props.total)

    watch(() => props.total, (val,prevVal) => {
      totalLocal.value = val
    })

    watch(() => props.cards, (val, prevVal) => {
       cardsInHand.value = val

    },{deep:true})

    return {
      cardsInHand,
      totalLocal
    }
  }
}
</script>

<style lang="scss" scoped>
#dealer-hand {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  .v-chip {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
  }
  .card {
    display: inline-block;
    transition: all 0.5s;
  }
  .card-enter, .card-leave-to
  /* .card-leave-active for <2.1.8 */ {
    opacity: 0;
    transform: scale(0);
  }
  .card-enter-to {
    opacity: 1;
    transform: scale(1);
  }

  .card-leave-active {
    /*position: absolute;*/
  }

  .card-move {
    opacity: 1;
    transition: all 0.5s;
  }
}
</style>
