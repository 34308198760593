<template lang="html">
  <div class="black-game-wrapper">
    <v-row>
      <v-col v-show="!gameStarted" cols="12" md="4" class="ml-auto mr-auto mt-5">
        <v-card>
          <v-card-title class="justify-center">
            Buy chips
          </v-card-title>
          <v-card-text class="">
            <v-text-field
              label="How much chips you want to buy?"
              prefix="$"
              v-model="tokensLeft"
              type="number"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="gameStarted = true;"
            >
              Start
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
    v-model="gameStarted"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    eager
    >
      <v-card tile>
        <v-toolbar
          dark
          color="primary"
          class="mb-8"
        >
          <v-btn
            icon
            dark
            @click="closeGame"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-toolbar>

        <div id="board" class="d-flex align-center justify-center flex-grow-1 flex-column">
          <CardDeck :cards="cards"></CardDeck>

          <DealerHand :cards="dealerCards" :total="dealerCardsSum"></DealerHand>

          <div id="bet_wrapper">
            <p class="px-6 py-1 font-weight-medium text-lg elevation-3 rounded-xl text--primary bg-card">{{ currentBet > 0 ? "$" + currentBet : "Place your bet!"}}</p>
            <v-btn
              @click="dealCards"
              color="success"
              v-if="!dealing && currentBet > 0"
            >
              Deal cards
            </v-btn>
            <v-btn
              outlined
              @click="addCardToPlayer"
              color="primary"
              v-if="showActions"
              class="mr-2"
            >
              Hit
            </v-btn>
            <v-btn
              outlined
              @click="splitCards"
              color="primary"
              v-if="showActions && playerCanSplit"
              class="ml-2 mr-2"
            >
              Split
            </v-btn>
            <v-btn
              outlined
              @click="doubleBet"
              color="primary"
              v-if="showActions"
              class="ml-2 mr-2"
            >
              Double
            </v-btn>
            <v-btn
              outlined
              @click="addCardsToDealer"
              color="primary"
              v-if="showActions"
              class="ml-2"
            >
              Stay
            </v-btn>
          </div>

          <PlayerHand
            :cards="playerCards"
            :cards-if-splitted="playerCards2"
            :total="playerCardsSum"
            :total-if-splitted="playerCardsSum2"
            :is-splitted="playerSplitted"
          >
          </PlayerHand>

        </div>

        <div class="tokens-left">
          Tokens left: <strong>${{ tokensLeft }}</strong>
        </div>
        <Tokens :balance="parseInt(tokensLeft)" @add-to-bet="addToBet"></Tokens>

        <v-snackbar
          v-model="snackbar.visible"
          :color="snackbar.color"
          timeout="2000"
        >
          <div class="text-center">
            <strong>{{snackbar.text}}</strong>
          </div>
        </v-snackbar>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import Card from './Card.vue'
import CardDeck from './CardDeck.vue'
import Tokens from './Tokens.vue'
import DealerHand from './DealerHand'
import PlayerHand from './PlayerHand'
import store from '@/store'
import { mdiClose } from '@mdi/js'
import blackjackStoreModule from './blackjackStoreModule'
import { ref, computed, onUnmounted, watch } from '@vue/composition-api'

export default {
  components: {
    Card,
    CardDeck,
    Tokens,
    DealerHand,
    PlayerHand
  },
  setup() {

    const BLACKJACK_STORE_MODULE_NAME = 'blackjack'

    // Register module
    if (!store.hasModule(BLACKJACK_STORE_MODULE_NAME)) {
      store.registerModule(BLACKJACK_STORE_MODULE_NAME, blackjackStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BLACKJACK_STORE_MODULE_NAME)) store.unregisterModule(BLACKJACK_STORE_MODULE_NAME)
    })

    const gameStarted = ref(false)
    const cards = ref([])
    const currentBet = ref(0)
    const tokensLeft = ref(1000)
    const dealing = ref(false)
    const dealerCards = ref([])
    const playerCards = ref([])
    const playerCards2 = ref([])
    const dealerCardsSum = ref(0)
    const playerCardsSum = ref(0)
    const playerCardsSum2 = ref(0)
    const showActions = ref(false);
    const playerCanSplit = ref(false);
    const playerSplitted = ref(false);
    const snackbar = ref({
      visible: false,
      color: "error",
      text: "BUSTED!"
    })

    const showSnackbar = (newsnackbar) => {
      snackbar.value = newsnackbar
    }

    const addCardsToDealer = () => {

      showActions.value = false

      let dealerHasAce = false

      if (dealerCards.value.length == 2 && dealerCards.value[0].flipped == false) {
        dealerCards.value[0].flipped = true
      } else {
        let next = cards.value.pop();
        if (next.value == "ace") {
          if ((dealerCardsSum.value + 11) > 21) {
            dealerCardsSum.value += 1
          } else {
            dealerCardsSum.value += 11
          }
          dealerHasAce = true
        } else {
          dealerCardsSum.value += next.value
        }
        dealerCards.value.push(next);
      }

      if (dealerCardsSum.value < 17 || ((dealerCardsSum.value == 17 || dealerCardsSum.value == 7) && dealerHasAce)) {
        setTimeout(function () {
          addCardsToDealer()
        },1000)
      } else if (dealerCardsSum.value > 21 || dealerCardsSum.value < playerCardsSum.value) {
        if (playerCardsSum.value == 21 && playerCards.value.length == 2) {
          tokensLeft.value += currentBet.value*2
          tokensLeft.value += currentBet.value/2
        } else {
          tokensLeft.value += currentBet.value*2
        }
        resetDeal()
        snackbar.value = {
          visible: true,
          color: "success",
          text: "YOU WIN!"
        }
      } else if (dealerCardsSum.value > playerCardsSum.value) {
        resetDeal()
        snackbar.value = {
          visible: true,
          color: "error",
          text: "YOU LOST!"
        }
      } else if (dealerCardsSum.value == playerCardsSum.value) {
        tokensLeft.value += currentBet.value
        resetDeal()
        snackbar.value = {
          visible: true,
          color: "warning",
          text: "IT'S A TIE!"
        }
      }

    }

    const addCardToPlayer = () => {
      let next = cards.value.pop();
      if (next.value == "ace") {
        if ((playerCardsSum.value + 11) > 21) {
          playerCardsSum.value += 1
        } else {
          playerCardsSum.value += 11
        }
      } else {
        playerCardsSum.value += next.value
      }
      playerCards.value.push(next);

      if (playerCardsSum.value > 21) {
        resetDeal()
        snackbar.value = {
          visible: true,
          color: "error",
          text: "BUSTED!"
        }
      }
    }

    const resetDeal = () => {
      showActions.value = false
      playerCanSplit.value = false
      setTimeout(function () {
        dealerCardsSum.value = playerCardsSum.value = 0
        dealerCards.value.length = playerCards.value.length = 0
        currentBet.value = 0
        dealing.value = false
      },2000)
    }

    const dealCards = () => {
      dealing.value = true

      let next = cards.value.pop();
      if (next.value == "ace") {
        playerCardsSum.value += 11
      } else {
        playerCardsSum.value += next.value
      }
      playerCards.value.push(next);

      setTimeout(function() {
        let next = cards.value.pop();
        next.flipped = false
        if (next.value == "ace") {
          dealerCardsSum.value += 11
        } else {
          dealerCardsSum.value += next.value
        }
        dealerCards.value.push(next);
      }, 500)

      setTimeout(function() {
        let next = cards.value.pop();
        if (next.value == "ace") {
          playerCardsSum.value += 11
        } else {
          playerCardsSum.value += next.value
        }
        playerCards.value.push(next);
      }, 1000)

      setTimeout(function() {
        let next = cards.value.pop();
        if (next.value == "ace") {
          dealerCardsSum.value += 11
        } else {
          dealerCardsSum.value += next.value
        }
        dealerCards.value.push(next);
      }, 1500)

      setTimeout(function() {
        showActions.value = true
        // if (playerCards.value[0].value == playerCards.value[1].value) {
          // playerCanSplit.value = true
        // }
      }, 1700)
    }

    const splitCards = () => {
      playerCanSplit.value = false
      playerSplitted.value = true
      currentBet.value *= 2
      tokensLeft.value -= currentBet.value
      let second_card = playerCards.value.pop()
      playerCardsSum.value = playerCards.value[0].value == 'ace' ? 11 : playerCards.value[0].value
      setTimeout(function () {
        playerCards2.value = [second_card]
        playerCardsSum2.value = second_card.value == 'ace' ? 11 : second_card.value
      },1)
    }

    const doubleBet = () => {
      tokensLeft.value -= currentBet.value
      currentBet.value *= 2
      showActions.value = false
      addCardToPlayer()
      setTimeout(function () {
        if (!snackbar.value.visible) {
          addCardsToDealer()
        }
      },1500)
    }

    const shuffleDeck = () => {

      let colors = ["hearts","clubs","diamonds","spades"]
      let heads = ["jack","queen","king","ace"]

      let iterator = 1
      for (var deck = 1; deck < 7; deck++) {
        colors.forEach((color) => {
          for (var i = 2; i < 11; i++) {
            cards.value.push({ value: i, image: `${i}_of_${color}`, flipped: true, index: "card_"+iterator })
            iterator++
          }
          heads.forEach((head) => {
            cards.value.push({ value: (head === 'ace' ? head : 10), image: `${head}_of_${color}`, flipped: true, index: "card_"+iterator })
            iterator++
          })

        });
      }

      for (let i = cards.value.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = cards.value[i];
        cards.value[i] = cards.value[j];
        cards.value[j] = temp;
      }

    }

    shuffleDeck()

    const closeGame = () => {
      gameStarted.value = false
      cards.value.length = 0
      shuffleDeck()
    }

    const addToBet = (value) => {
      if (dealing.value) {
        return
      }
      currentBet.value += value
      tokensLeft.value -= value
    }

    return {
      closeGame,
      gameStarted,
      cards,
      currentBet,
      addToBet,
      tokensLeft,
      dealCards,
      dealing,
      dealerCards,
      playerCards,
      playerCards2,
      dealerCardsSum,
      playerCardsSum,
      playerCardsSum2,
      addCardsToDealer,
      addCardToPlayer,
      showActions,
      snackbar,
      showSnackbar,
      playerCanSplit,
      splitCards,
      doubleBet,
      playerSplitted,
      icons: {
        mdiClose
      }
    }

  }
}
</script>

<style lang="scss" scoped>
#board {
  position: relative;
  height: calc(100vh - 120px);
  #bet_wrapper {
    text-align: center;
    p {
      width: 200px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.tokens-left {
  position: absolute;
  bottom: 90px;
  left: 10px;
}
</style>
