<template>
  <div class="">
    <div :class="isSplittedLocal ? 'player-hand' : ' player-hand not-splitted'">
      <v-chip color="secondary" v-if="totalLocal > 0">
        <strong>{{totalLocal}}</strong>
      </v-chip>
      <transition-group name="card" :duration="500">
        <div class="card" v-for="(card,index) in cardsInHand" :key="card.index">
          <Card
            :number="card.image"
            :flipped="card.flipped"
          ></Card>
        </div>
      </transition-group>
    </div>
    <div class="player-hand second-hand" v-if="isSplittedLocal">
      <v-chip color="secondary" v-if="totalIfSplittedLocal > 0">
        <strong>{{totalIfSplitted}}</strong>
      </v-chip>
      <transition-group name="card" :duration="500">
        <div class="card" v-for="card in cardsInHandIfSplitted" :key="card.index">
          <Card
            :number="card.image"
            :flipped="card.flipped"
          ></Card>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import Card from './Card.vue'
import { ref, watch } from '@vue/composition-api'

export default {
  components: {
    Card
  },
  props: {
    cards: Array,
    cardsIfSplitted: Array,
    total: Number,
    totalIfSplitted: Number,
    isSplitted: Boolean
  },
  setup(props, {emit}) {

    const cardsInHand = ref(props.cards)
    const cardsInHandIfSplitted = ref(props.cardsIfSplitted)
    const totalLocal = ref(props.total)
    const totalIfSplittedLocal = ref(props.totalIfSplitted)
    const isSplittedLocal = ref(props.isSplitted)

    watch(() => props.total, (val,prevVal) => {
      totalLocal.value = val
    })

    watch(() => props.isSplitted, (val,prevVal) => {
      isSplittedLocal.value = val
    })

    watch(() => props.cards, (val, prevVal) => {
       cardsInHand.value = val
    })

    watch(() => props.cardsIfSplitted, (val, prevVal) => {
       cardsInHandIfSplitted.value = val
    })

    watch(() => props.totalIfSplitted, (val,prevVal) => {
      totalIfSplittedLocal.value = val
    })

    return {
      totalLocal,
      cardsInHand,
      isSplittedLocal,
      totalIfSplittedLocal,
      cardsInHandIfSplitted
    }
  }
}
</script>

<style lang="scss" scoped>

.player-hand {
  position: absolute;
  bottom: 0;
  width: 75%;
  text-align: center;
  left: 0;
  &.not-splitted {
    width: 100%;
  }
  &.second-hand {
    left: 25%
  }
  .v-chip {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
  }
  .card {
    display: inline-block;
    transition: all 0.5s;
  }
  .card-enter, .card-leave-to {
    opacity: 0;
    transform: scale(0);
  }
  .card-enter {
    transform: translate(50vw,-100%);
  }
  .card-enter-to {
    opacity: 1;
    transform: scale(1);
  }

  .card-enter-active {
    // position: absolute;
  }

  .card-leave-active {
    /*position: absolute;*/
  }

  .card-move {
    opacity: 1;
    transition: all 0.5s;
  }
}
</style>
