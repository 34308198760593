<template lang="html">
  <div id="tokens">
      <v-btn
      fab
      dark
      large
      :color="token.color"
      @click="$emit('add-to-bet',token.value)"
      v-for="token in tokens"
      v-show="currentBalance >= token.value"
    >
      {{ token.value }}
    </v-btn>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

export default {
  props: {
    balance: Number
  },
  setup(props) {
    const tokens = [
      {
        color: "error",
        value: 5
      },
      {
        color: "secondary",
        value: 10
      },
      {
        color: "primary",
        value: 50
      },
      {
        color: "success",
        value: 100
      },
      {
        color: "warning",
        value: 500
      }
    ]

    const currentBalance = ref(props.balance)

    watch(() => props.balance, (val, prevVal) => {
       currentBalance.value = val
    })

    return {
      currentBalance,
      tokens
    }
  }
}
</script>

<style lang="scss">
#tokens {
  position: absolute;
  bottom: 10px;
  button {
    margin-left: 10px
  }
}
</style>
